import {ActionTypes, DataTypes} from "./types";
import {RestDataSource} from "./api/restDataSource";

const dataSource = new RestDataSource();

export const startLoading = (dataType, text) => {
    //console.log('actionCreators startLoading', dataType, text);
    return {
        type: ActionTypes.START_LOADING,
        payload: {dataType, text}
    }
};

export const getCollection = (dataType, params, onSuccess, onError) => {
    return ({
        type: ActionTypes.GET_COLLECTION,
        onError: onError,
        payload: dataSource.getCollection(dataType, params)
            .then(response => {
                if (onSuccess) {
                    onSuccess(response.data._embedded[dataType]);
                }
                return {
                    dataType,
                    data: response.data._embedded[dataType],
                    total: Number(response.data.total_items),
                    params
                }
            })
    })
};

export const getEntity = (dataType, id, onSuccess, onError) => {
    //console.log('actionCreators getEntity', dataType, id);
    return {
        type: ActionTypes.GET_ENTITY,
        onError: onError,
        payload: dataSource.getEntity(dataType, id)
            .then(response => {
                if (onSuccess) {
                    onSuccess(response.data);
                }
                return {
                    dataType,
                    data: response.data
                }
            })
    }
};

export const saveEntity = (dataType, id, data, onSuccess, onError) => {
    //console.log('actionCreators updateEntity', dataType, id, data);
    return {
        type: ActionTypes.SAVE_ENTITY,
        onSuccess: onSuccess,
        onError: onError,
        payload: dataSource.saveEntity(dataType, id, data)
            .then(response => {
                return {}
            })
    }
};

export const deleteEntity = (dataType, id, onSuccess, onError) => {
    //console.log('actionCreators deleteEntity', dataType, id);
    return {
        type: ActionTypes.DELETE_ENTITY,
        onSuccess: onSuccess,
        onError: onError,
        payload: dataSource.deleteEntity(dataType, id)
            .then(response => {
                return {}
            })
    }
};

export const startLoader = (text) => ({
    type: ActionTypes.START_LOADER,
    payload: {text}
});

export const loadingError = (error) => ({
    type: ActionTypes.LOADING_ERROR,
    error: error
});

export const login = (credentials, onSuccess, onError) => ({
    type: ActionTypes.LOGIN,
    onSuccess: onSuccess,
    onError: onError,
    payload: dataSource.login(credentials)
});

export const loginError = (error) => ({
    type: ActionTypes.LOGIN_ERROR,
    error: error
});

export const logout = () => ({
    type: ActionTypes.LOGOUT,
    payload: dataSource.logout()
});

export const register = (data, onSuccess, onError) => ({
    type: ActionTypes.REGISTER,
    onSuccess: onSuccess,
    onError: onError,
    payload: dataSource.register(data)
});

export const registerError = (error) => ({
    type: ActionTypes.REGISTER_ERROR,
    error: error
});

export const recoverPassword = (data, onSuccess, onError) => ({
    type: ActionTypes.RECOVER_PASSWORD,
    onSuccess: onSuccess,
    onError: onError,
    payload: dataSource.recoverPassword(data)
});

export const recoverPasswordError = (error) => ({
    type: ActionTypes.RECOVER_PASSWORD_ERROR,
    error: error
});

export const setPassword = (data, onSuccess, onError) => ({
    type: ActionTypes.SET_PASSWORD,
    onSuccess: onSuccess,
    onError: onError,
    payload: dataSource.setPassword(data)
});

export const setPasswordError = (error) => ({
    type: ActionTypes.SET_PASSWORD_ERROR,
    error: error
});

export const downloadFile = (file, onError) => {
    return {
        type: ActionTypes.DOWNLOAD_FILE,
        onError: onError,
        payload: dataSource.getFile(file.code)
            .then(response => {
                //console.log(response);
                let options = {type: response.data.type}
                const blob = new Blob(
                    [response.data],
                    options);
                const fileURL = URL.createObjectURL(blob);

                const contentDisposition = response.headers['content-disposition'];
                let fileName = 'unknown';
                if (contentDisposition) {
                    const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
                    if (fileNameMatch.length === 2)
                        fileName = fileNameMatch[1];
                }

                const link = document.createElement('a');
                link.href = fileURL;
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
                link.remove();
                URL.revokeObjectURL(fileURL);
                //window.open(fileURL);
            })
    }
};

export const downloadFileError = (error) => ({
    type: ActionTypes.DOWNLOAD_FILE_ERROR,
    error: error
});

export const openFile = (file, onError) => {
    return {
        type: ActionTypes.OPEN_FILE,
        onError: onError,
        payload: dataSource.getFile(file.code)
            .then(response => {
                //console.log(response);
                let options = {type: response.data.type}
                const blob = new Blob(
                    [response.data],
                    options);
                const fileURL = URL.createObjectURL(blob);

                const contentDisposition = response.headers['content-disposition'];
                let fileName = 'unknown';
                if (contentDisposition) {
                    const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
                    if (fileNameMatch.length === 2)
                        fileName = fileNameMatch[1];
                }


                return {...file, fileURL, fileName, data: response.data};
            })
    }
};

export const openFileError = (error) => ({
    type: ActionTypes.OPEN_FILE_ERROR,
    error: error
});

export const profile = (onSuccess, onError) => ({
    type: ActionTypes.PROFILE,
    onSuccess: onSuccess,
    onError: onError,
    payload: dataSource.profile()
});

export const profileError = (error) => ({
    type: ActionTypes.PROFILE_ERROR,
    error: error
});

export const getList = (listId, onError) => {
    return ({
        type: ActionTypes.GET_LIST,
        onError: onError,
        payload: dataSource.getList(listId).then(response => {
            return {
                listId: listId,
                data: response.data.items
            }
        })
    })
};

export const searchCompositions = (search, onSuccess, onError) => {
    return ({
        type: ActionTypes.SEARCH_COMPOSITIONS,
        onError: onError,
        payload: dataSource.getCollection(DataTypes.COMPOSITIONS, {filter: {title: search}}).then(response => {
            onSuccess(response.data._embedded[DataTypes.COMPOSITIONS]);
        })
    })
};

export const printRepertoire = (id, part, onSuccess, onError) => {
    return ({
        type: ActionTypes.PRINT_REPERTOIRE,
        onError: onError,
        payload: dataSource.printRepertoire(id, part).then(response => {
            onSuccess(response.data);
        })
    })
};

export const getEvent = (id, part, onSuccess, onError) => {
    return {
        type: ActionTypes.GET_EVENT,
        onError: onError,
        payload: dataSource.getEvent(id, part)
            .then(response => {
                if (onSuccess) {
                    onSuccess(response.data);
                }
                return {
                    data: response.data
                }
            })
    }
};

export const getNearest = (onSuccess, onError) => {
    return ({
        type: ActionTypes.GET_NEAREST,
        onError: onError,
        payload: dataSource.getNearest()
            .then(response => {
                if (onSuccess) {
                    onSuccess(response.data);
                }
                return {
                    data: response.data
                }
            })
    })
};

export const printSongbookIndex = (id, onSuccess, onError) => {
    return ({
        type: ActionTypes.PRINT_SONGBOOK_INDEX,
        onError: onError,
        payload: dataSource.printSongbookIndex(id).then(response => {
            onSuccess(response.data);
        })
    })
};

export const printChoirSongbook = (id, onSuccess, onError) => {
    return ({
        type: ActionTypes.PRINT_CHOIR_SONGBOOK,
        onError: onError,
        payload: dataSource.printChoirSongbook(id).then(response => {
            onSuccess(response.data);
        })
    })
};
