import React, {Component} from 'react';
import {Menu, Layout} from "antd";
import {
    BookOutlined,
    PoweroffOutlined,
    CalendarOutlined,
    PlayCircleOutlined
} from '@ant-design/icons';
import {Link, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {getUsername} from "./helpers/auth";

class Navbar extends Component {

    render() {
        //console.log(this.props.settings.sidebar.collapsed);
        const {location} = this.props;
        const parts = location.pathname.split('/');
        const root = '/' + parts[1];
        let current = '';
        for (let i = 1; i < parts.length; i++) {
            current += '/' + parts[i];
        }
        return <Layout className="layout">
            <Menu theme="dark"
                  selectedKeys={[root, current]}
                  mode="horizontal">
                <Menu.Item
                    key="/home">
                    <Link to="/">
                        <img className="logo" alt="logo" src="/img/logo.svg"/>
                    </Link>
                </Menu.Item>

                <Menu.Item
                    key="/compositions">
                    <Link to="/compositions">
                        <BookOutlined/> Utwory
                    </Link>
                </Menu.Item>

                <Menu.Item
                    key="/calendar">
                    <Link to="/calendar">
                        <CalendarOutlined/> Kalendarz
                    </Link>
                </Menu.Item>

                <Menu.Item
                    key="/exercises">
                    <Link to="/exercises">
                        <PlayCircleOutlined/> Ćwiczenia
                    </Link>
                </Menu.Item>


                <Menu.Item
                    style={{float: 'right'}}
                    key="/login">
                    <Link to="/login">
                        <PoweroffOutlined/> Wyloguj {getUsername()}
                    </Link>
                </Menu.Item>
            </Menu>

        </Layout>
    }
}

const RouterNavbar = withRouter(Navbar);

const mapStateToProps = (state) => ({
    settings: state.Settings
});

export default connect(mapStateToProps, {})(RouterNavbar);
