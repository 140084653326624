import React, {Component} from "react";
import {connect} from "react-redux";
import {
    downloadFile, downloadFileError,
    getNearest, loadingError, startLoader
} from "../../data/actionCreators";
import {Spin} from "antd";
import {errorMessage} from "../../helpers/error";
import HomeContainer from "./HomeContainer";

const mapStateToProps = (dataStore) => ({
    ...dataStore
});

const mapDispatchToProps = dispatch => ({
    loadNearest: (history, onSuccess) => {
        dispatch(startLoader('Pobieranie danych'));
        dispatch(getNearest((data) => onSuccess(data), (error) => {
            dispatch(loadingError(error));
            errorMessage(error, history);
        }));
    },
    downloadFile: (file, history) => {
        dispatch(startLoader('Pobieranie pliku'));
        dispatch(downloadFile(file, (error) => {
            dispatch(downloadFileError(error));
            errorMessage(error, history);
        }));
    },
});

export const HomeConnector = connect(mapStateToProps, mapDispatchToProps)(
    class extends Component {
        render() {
            return <Spin spinning={this.props.loading} tip={this.props.text}>
                <HomeContainer {...this.props}/>
            </Spin>
        }
    }
);
