import React, {Component} from "react";
import {Col, Empty, Layout, Row, Space} from "antd";
import {BookOutlined, CalendarOutlined, PlayCircleOutlined} from "@ant-design/icons";
import moment from "moment";
import {Link} from "react-router-dom";
import {fileTypes} from "../../data/fileTypes";
import ReactPlayer from "react-player";

const {Content} = Layout;

const cardStyles = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: 'black',
    gap: '10px'
};

class HomeContainer extends Component {
    state = {
        nearest: {
            compositions: [],
            files: [],
            links: [],
            events: []
        }
    }

    componentDidMount() {
        this.props.loadNearest(this.props.history, (data) => {
            this.setState({
                nearest: {
                    compositions: data.compositions,
                    files: data.files.map(item => {
                        return {...item, file_type: fileTypes[item.file_type_id]}
                    }),
                    links: data.links,
                    events: data.events.map(item => {
                        const sd = moment(item.start).format('D MMMM YYYY dddd HH:mm');
                        return {...item, start_f: sd}
                    })
                }
            });
        });
    }

    downloadFile(e, file) {
        e.preventDefault();
        this.props.downloadFile(file, this.props.history);
    }

    render() {
        return <Layout style={{padding: '0 24px 24px'}}>
            <Content style={{
                background: '#fff',
                padding: 24,
                margin: 0,
            }}>
                <div className="site-card-wrapper">
                    <Row gutter={16}>
                        <Col xs={24} md={8}>
                            <a href={'/compositions'} title={'Przejdź do bazy utworów'}>
                                <div style={cardStyles}>
                                    <BookOutlined style={{fontSize: '5em'}}/>
                                    <h2>Utwory</h2>
                                </div>
                            </a>
                        </Col>
                        <Col xs={24} md={8}>
                            <a href={'/calendar'} title={'Przejdź do kalendarza'}>
                                <div style={cardStyles}>
                                    <CalendarOutlined style={{fontSize: '5em'}}/>
                                    <h2>Kalendarz</h2>
                                </div>
                            </a>
                        </Col>
                        <Col xs={24} md={8}>
                            <a href={'/exercises/clefs'} title={'Przejdź do ćwiczeń'}>
                                <div style={cardStyles}>
                                    <PlayCircleOutlined style={{fontSize: '5em'}}/>
                                    <h2>Ćwiczenia</h2>
                                </div>
                            </a>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col xs={24} md={8}>
                            <h2 style={{paddingTop: '34px'}}>Ostatnio dodane utwory</h2>
                            {
                                this.state.nearest.compositions.length > 0 ? this.state.nearest.compositions.map((item, key) => {
                                    return <div key={key} style={{padding: '10px'}}>
                                        <Link to={'/compositions/' + item.id}>
                                            {item.title} <small>{item.subtitle}</small>
                                        </Link>
                                    </div>
                                }) : <Empty description={'Brak utworów'}></Empty>
                            }
                        </Col>
                        <Col xs={24} md={8}>
                            <h2 style={{paddingTop: '34px'}}>Najbliższe wydarzenia</h2>
                            {
                                this.state.nearest.events.length > 0 ? this.state.nearest.events.map((item, key) => {
                                    return <div key={key} style={{padding: '10px'}}>
                                        <Link to={'/repertoire/' + item.id}>
                                            {item.start_f} {item.type_name} <i>{item.description}</i>
                                        </Link>
                                    </div>
                                }) : <Empty description={'Brak wydarzeń'}></Empty>
                            }
                        </Col>
                        <Col xs={24} md={8}>
                            <h2 style={{paddingTop: '34px'}}>Ostatnio dodane pliki</h2>
                            {
                                this.state.nearest.files.length > 0 ? this.state.nearest.files.map((item, key) => {
                                    return <div key={key} style={{padding: '10px'}}>
                                        <a onClick={(e) => this.downloadFile(e, item)} href={void(0)}>
                                            <Space>
                                                <span>{item.file_type.icon}</span>
                                                <span>{item.composition_title} - {item.part_name} ({item.file_type.type})</span>
                                            </Space>
                                        </a>
                                    </div>
                                }) : <Empty description={'Brak plików'}></Empty>
                            }
                        </Col>
                    </Row>

                    <h2 style={{paddingTop: '34px'}}>Ostatnio dodane linki do utworów</h2>
                    <Row gutter={16}>
                        {
                            this.state.nearest.links.length > 0 ? this.state.nearest.links.map((item, key) => {
                                return <Col key={key} xs={24} md={8}>
                                    <h3 style={{marginTop: '16px'}}>
                                        <Link to={'/compositions/' + item.composition_id}>{item.composition_title}</Link>
                                    </h3>
                                    <h4>{item.link_title}</h4>
                                    <ReactPlayer
                                        url={item.link}
                                        controls={true}
                                        width='100%'
                                        style={{marginBottom: '1em'}}
                                    />
                                </Col>
                            }) : <Empty description={'Brak linków'}></Empty>
                        }
                    </Row>

                </div>
            </Content>
        </Layout>;
    }
}

export default HomeContainer;
