import React, {Component} from 'react';
import {Modal, Form, Input, Select, Button, Row, Col, Tabs, Upload, message} from 'antd';
import {
    CloseOutlined,
    SaveOutlined,
    UploadOutlined
} from '@ant-design/icons';
import {urls} from "../../data/api/urls";
import {RestDataSource} from "../../data/api/restDataSource";

const layout = {
    labelCol: {
        span: 6,
    },
    wrapperCol: {
        span: 18,
    },
};

const tailFormItemLayout = {
    wrapperCol: {
        xs: {
            span: 24,
            offset: 0,
        }
    },
};

export class CompositionEditModal extends Component {
    formRef = React.createRef();

    handleOk = () => {
        this.formRef.current
            .validateFields()
            .then((values) => {
                //this.formRef.current.resetFields();
                this.props.handleOk(this.props.data.id, values);
            })
            .catch((info) => {
                console.log('Validate Failed:', info);
            });
    }

    handleCancel = () => {
        //this.formRef.current.resetFields();
        this.props.handleCancel();
    }

    render() {
        const uploadProps = {
            name: 'file',
            action: urls.upload_file,
            headers: RestDataSource.getAuthorizationHeader(),
            showUploadList: false,
            data: {
                composition_id: this.props.data.id
            },
            onChange(info) {
                if (info.file.status !== 'uploading') {
                    console.log(info.file, info.fileList);
                }
                if (info.file.status === 'done') {
                    message.success(`Plik ${info.file.name} wysłany poprawnie`);
                } else if (info.file.status === 'error') {
                    message.error(`Błąd wysyłania pliku ${info.file.name}.`);
                }
            },
        };
        //console.log(this.props.data)
        return <Modal title={this.props.title}
                      visible={this.props.visible}
                      width={1000}
                      onOk={this.handleOk}
                      onCancel={this.handleCancel}
                      footer={[
                          <Button icon={<CloseOutlined/>} key="cancel" onClick={this.handleCancel}>
                              Anuluj
                          </Button>,
                          <Button icon={<SaveOutlined/>} key="search" type="primary" onClick={this.handleOk}>
                              Zapisz
                          </Button>,
                      ]}>
            <Form {...layout}
                  ref={this.formRef}
                  fields={Object.keys(this.props.data).map(key => ({
                      name: key,
                      value: this.props.data[key]
                  }))
                  }
            >

                <Row gutter={12}>
                    <Col xs={24} md={12}>

                        <Form.Item
                            name="title"
                            label="Tytuł"
                            rules={[
                                {
                                    required: true,
                                    message: 'Podaj tytuł utworu'
                                }
                            ]}
                        >
                            <Input/>
                        </Form.Item>

                        <Form.Item
                            name="subtitle"
                            label="Podtytuł"
                        >
                            <Input/>
                        </Form.Item>

                        <Form.Item name="categories" label="Kategoria">
                            <Select allowClear={true}
                                    showSearch={true}
                                    optionFilterProp={'label'}
                                    mode="multiple"
                            >
                                {
                                    this.props.categories ?
                                        this.props.categories.map((category) => <Select.Option key={category.id}
                                                                                               value={category.id}
                                                                                               label={category.name}>
                                            {category.name}
                                        </Select.Option>) : null
                                }
                            </Select>
                        </Form.Item>

                        <Form.Item name="lyrics_author" label="Autor tekstu">
                            <Input/>
                        </Form.Item>

                        <Form.Item name="composer" label="Kompozytor">
                            <Input/>
                        </Form.Item>

                        <Form.Item name="arranger" label="Opracowanie">
                            <Input/>
                        </Form.Item>

                        <Form.Item name="source_id" label="Źródło">
                            <Select allowClear={true}
                                    showSearch={true}
                                    optionFilterProp={'label'}>
                                {
                                    this.props.sources ?
                                        this.props.sources.map((source) => <Select.Option key={source.id}
                                                                                          value={source.id}
                                                                                          label={source.title}>
                                            {source.title}
                                        </Select.Option>) : null
                                }
                            </Select>
                        </Form.Item>

                        <Form.Item label="Plik MusicXML">
                            <Upload {...uploadProps}>
                                <Button icon={<UploadOutlined/>}>Załaduj</Button>
                            </Upload>
                        </Form.Item>

                        <Form.Item name="songbooks" label="Śpiewniki">
                            <Select allowClear={true}
                                    showSearch={true}
                                    optionFilterProp={'label'}
                                    mode="multiple"
                            >
                                {
                                    this.props.songbooks ?
                                        this.props.songbooks.map((songbook) => <Select.Option key={songbook.id}
                                                                                               value={songbook.id}
                                                                                               label={songbook.title}>
                                            {songbook.title}
                                        </Select.Option>) : null
                                }
                            </Select>
                        </Form.Item>

                    </Col>

                    <Col xs={24} md={12}>

                        <Tabs defaultActiveKey="1"
                              type="card"
                              tabBarStyle={{marginBottom: 0}}
                        >
                            <Tabs.TabPane tab={'Treść'} key={1}>
                                <Form.Item {...tailFormItemLayout} name="lyrics">
                                    <Input.TextArea rows={20}/>
                                </Form.Item>
                            </Tabs.TabPane>

                            <Tabs.TabPane tab={'Opis'} key={2}>
                                <Form.Item {...tailFormItemLayout} name="description">
                                    <Input.TextArea rows={20}/>
                                </Form.Item>
                            </Tabs.TabPane>
                        </Tabs>
                    </Col>

                </Row>

            </Form>
        </Modal>
    }
}
