import {DataTypes} from "../types";

export const protocol = process.env.REACT_APP_PROTOCOL;
export const hostname = process.env.REACT_APP_HOSTNAME;
export const port = process.env.REACT_APP_PORT;

export const urls = {
    [DataTypes.SOURCES]: `${protocol}://${hostname}:${port}/api/sources`,
    [DataTypes.CATEGORIES]: `${protocol}://${hostname}:${port}/api/categories`,
    [DataTypes.SONGBOOKS]: `${protocol}://${hostname}:${port}/api/songbooks`,
    [DataTypes.COMPOSITIONS]: `${protocol}://${hostname}:${port}/api/compositions`,
    [DataTypes.COMPOSITION_FILES]: `${protocol}://${hostname}:${port}/api/composition-files`,
    [DataTypes.COMPOSITION_LINKS]: `${protocol}://${hostname}:${port}/api/composition-links`,
    [DataTypes.FEASTS]: `${protocol}://${hostname}:${port}/api/feasts`,
    [DataTypes.EVENTS]: `${protocol}://${hostname}:${port}/api/events`,
    [DataTypes.EVENT_PARTS]: `${protocol}://${hostname}:${port}/api/event-parts`,
    [DataTypes.PARTS]: `${protocol}://${hostname}:${port}/api/parts`,
    get_file: `${protocol}://${hostname}:${port}/api/files`,
    upload_file: `${protocol}://${hostname}:${port}/api/upload`,
    login: `${protocol}://${hostname}:${port}/oauth`,
    logout: `${protocol}://${hostname}:${port}/oauth/revoke`,
    register: `${protocol}://${hostname}:${port}/api/register`,
    recover_password: `${protocol}://${hostname}:${port}/api/recover-password`,
    set_password: `${protocol}://${hostname}:${port}/api/set-password`,
    profile: `${protocol}://${hostname}:${port}/api/profile`,
    lists: `${protocol}://${hostname}:${port}/api/lists`,
    print_repertoire: `${protocol}://${hostname}:${port}/api/repertoire`,
    e: `${protocol}://${hostname}:${port}/api/e`,
    nearest: `${protocol}://${hostname}:${port}/api/nearest`,
    print_songbook_index: `${protocol}://${hostname}:${port}/api/songbook/index`,
    print_choir_songbook: `${protocol}://${hostname}:${port}/api/songbook/choir`
};
