import React, {Component} from "react";
import {Layout, Row, Col} from "antd";
import {CompositionList} from "./CompositionList";
import {CompositionView} from "./CompositionView";
import {DataTypes} from "../../data/types";
import {CompositionToolbar} from "./CompositionToolbar";

const {Content} = Layout;

class CompositionContainer extends Component {
    state = {
        composition: {}
    }

    componentDidMount() {
        const {id} = this.props.match.params;
        if (id) {
            this.props.loadEntity(DataTypes.COMPOSITIONS, id, this.props.history);
        }
    }

    onSelectComposition = (composition) => {
        this.props.loadEntity(DataTypes.COMPOSITIONS, composition.id, this.props.history);
        this.setState({composition});
    }

    onChangeList = (pagination, filters, sorter, extra) => {
        //console.log('change params', pagination, filters, sorter, extra);
        if (extra.action === 'paginate') {
            const params = {
                ...this.props[`${DataTypes.COMPOSITIONS}_params`],
                page: pagination.current
            };
            this.props.loadCollection(DataTypes.COMPOSITIONS, params, this.props.history);
        } else if (extra.action === 'sort') {
            const params = {
                ...this.props[`${DataTypes.COMPOSITIONS}_params`],
                sort: sorter.column ? {column: sorter.column.dataIndex, order: sorter.order} : null
            };
            this.props.loadCollection(DataTypes.COMPOSITIONS, params, this.props.history);
        }
    }

    downloadFile = (file) => {
        console.log(file);
        this.props.downloadFile(file, this.props.history);
    }

    openFile = (file) => {
        this.props.openFile(file, this.props.history);
    }

    deleteFile = (file) => {
        this.props.deleteFile(file, this.props.history);
    }

    addFile = (data) => {
        this.props.addFile(data, this.props.history);
    }

    addLink = (data) => {
        this.props.addLink(data, this.props.history);
    }

    deleteLink = (link) => {
        this.props.deleteLink(link, this.props.history);
    }

    render() {
        return <Layout style={{background: '#fff', margin: '0 24px', padding: '0'}}>

            <CompositionToolbar {...this.props}/>

            <Row gutter={{xs: 4, sm: 8, md: 12, lg: 24}}>
                <Col xs={24} sm={24} md={10} lg={6} xl={6}>
                    <CompositionList
                        data={this.props.compositions}
                        current={this.state.composition}
                        params={this.props.compositions_params}
                        total={this.props.compositions_total}
                        onSelect={this.onSelectComposition}
                        onChangeList={this.onChangeList}
                    />
                </Col>

                <Col xs={24} sm={24} md={14} lg={18} xl={18}>
                    <Content style={{padding: '1em', minHeight: 280}}>
                        <CompositionView
                            downloadFile={this.downloadFile}
                            openFile={this.openFile}
                            deleteFile={this.deleteFile}
                            addFile={this.addFile}
                            deleteLink={this.deleteLink}
                            addLink={this.addLink}
                            parts={this.props.parts}
                            composition={this.props.compositions_entity}
                            file={this.props.file}
                        />
                    </Content>
                </Col>
            </Row>
        </Layout>;
    }
}

export default CompositionContainer;
