import React, {Component} from "react";
import {connect} from "react-redux";
import {
    getCollection,
    getEntity,
    saveEntity,
    loadingError,
    startLoading,
    startLoader,
    downloadFile,
    downloadFileError,
    openFile,
    openFileError,
    deleteEntity,
    printSongbookIndex, printChoirSongbook
} from "../../data/actionCreators";
import CompositionContainer from "./CompositionContainer";
import {DataTypes} from "../../data/types";
import {Spin} from "antd";
import {errorMessage, updateErrorMessage} from "../../helpers/error";

const mapStateToProps = (dataStore) => ({
    ...dataStore
});

const mapDispatchToProps = dispatch => ({
    loadCollection: (dataType, params, history, onSuccess) => {
        dispatch(startLoading(dataType, 'Pobieranie danych śpiewnika'));
        dispatch(getCollection(dataType, params, onSuccess, (error) => {
            dispatch(loadingError(error));
            errorMessage(error, history);
        }));
    },
    loadEntity: (dataType, id, history) => {
        dispatch(startLoading(dataType, 'Pobieranie danych utworu'));
        dispatch(getEntity(dataType, id, null, (error) => {
            dispatch(loadingError(error));
            errorMessage(error, history);
        }));
    },
    saveEntity: (dataType, id, data, history) => {
        dispatch(startLoading(dataType, 'Aktualizacja utworu'));
        dispatch(saveEntity(dataType, id, data, () => {
            dispatch(startLoading(dataType, 'Pobieranie danych utworu'));
            dispatch(getEntity(dataType, id, null, (error) => {
                dispatch(loadingError(error));
                errorMessage(error, history);
            }));
        }, (error) => {
            dispatch(loadingError(error));
            updateErrorMessage(error, history);
        }));
    },
    downloadFile: (file, history) => {
        dispatch(startLoader('Pobieranie pliku'));
        dispatch(downloadFile(file, (error) => {
            dispatch(downloadFileError(error));
            errorMessage(error, history);
            //console.log(error);
        }));
    },
    openFile: (file, history) => {
        //console.log(file);
        dispatch(startLoader('Otwieranie pliku'));
        dispatch(openFile(file, (error) => {
            dispatch(openFileError(error));
            errorMessage(error, history);
            //console.log(error);
        }));
    },
    deleteFile: (data, history) => {
        dispatch(startLoader('Usuwanie pliku'));
        dispatch(deleteEntity(DataTypes.COMPOSITION_FILES, data.id, () => {
            dispatch(startLoading(DataTypes.COMPOSITIONS, 'Pobieranie danych utworu'));
            dispatch(getEntity(DataTypes.COMPOSITIONS, data.composition_id, null, (error) => {
                dispatch(loadingError(error));
                errorMessage(error, history);
            }));
        }, (error) => {
            dispatch(loadingError(error));
            updateErrorMessage(error, history);
        }));
    },
    addFile: (data, history) => {
        dispatch(startLoader('Dodawanie pliku'));
        dispatch(saveEntity(DataTypes.COMPOSITION_FILES, null, data, () => {
            dispatch(startLoading(DataTypes.COMPOSITIONS, 'Pobieranie danych utworu'));
            dispatch(getEntity(DataTypes.COMPOSITIONS, data.composition_id, null, (error) => {
                dispatch(loadingError(error));
                errorMessage(error, history);
            }));
        }, (error) => {
            dispatch(loadingError(error));
            updateErrorMessage(error, history);
        }));
    },
    addLink: (data, history) => {
        dispatch(startLoader('Dodawanie linku'));
        dispatch(saveEntity(DataTypes.COMPOSITION_LINKS, null, data, () => {
            dispatch(startLoading(DataTypes.COMPOSITIONS, 'Pobieranie danych utworu'));
            dispatch(getEntity(DataTypes.COMPOSITIONS, data.composition_id, null, (error) => {
                dispatch(loadingError(error));
                errorMessage(error, history);
            }));
        }, (error) => {
            dispatch(loadingError(error));
            updateErrorMessage(error, history);
        }));
    },
    deleteLink: (data, history) => {
        dispatch(startLoader('Usuwanie linku'));
        dispatch(deleteEntity(DataTypes.COMPOSITION_LINKS, data.id, () => {
            dispatch(startLoading(DataTypes.COMPOSITIONS, 'Pobieranie danych utworu'));
            dispatch(getEntity(DataTypes.COMPOSITIONS, data.composition_id, null, (error) => {
                dispatch(loadingError(error));
                errorMessage(error, history);
            }));
        }, (error) => {
            dispatch(loadingError(error));
            updateErrorMessage(error, history);
        }));
    },
    printSongbookIndex: (id, history) => {
        dispatch(startLoader('Drukowanie spisu'));
        dispatch(printSongbookIndex(id, (data) => {
            const file = new Blob(
                [data],
                {type: 'application/pdf'});
            //Build a URL from the file
            const fileURL = URL.createObjectURL(file);
            //Open the URL on new Window
            window.open(fileURL);
        }, (error) => {
            dispatch(loadingError(error));
            errorMessage(error, history);
        }));
    },
    printChoirSongbook: (id, history) => {
        dispatch(startLoader('Drukowanie śpiewnika dla chóru'));
        dispatch(printChoirSongbook(id, (data) => {
            const file = new Blob(
                [data],
                {type: 'application/pdf'});
            //Build a URL from the file
            const fileURL = URL.createObjectURL(file);
            //Open the URL on new Window
            window.open(fileURL);
        }, (error) => {
            dispatch(loadingError(error));
            errorMessage(error, history);
        }));
    }
});

export const CompositionConnector = connect(mapStateToProps, mapDispatchToProps)(
    class extends Component {
        componentDidMount = () => {
            this.props.loadCollection(DataTypes.SOURCES, this.props[`${DataTypes.SOURCES}_params`], this.props.history);
            this.props.loadCollection(DataTypes.CATEGORIES, this.props[`${DataTypes.CATEGORIES}_params`], this.props.history);
            this.props.loadCollection(DataTypes.SONGBOOKS, this.props[`${DataTypes.SONGBOOKS}_params`], this.props.history);
            this.props.loadCollection(DataTypes.COMPOSITIONS, this.props[`${DataTypes.COMPOSITIONS}_params`], this.props.history);
            this.props.loadCollection(DataTypes.PARTS, this.props[`${DataTypes.PARTS}_params`], this.props.history);
        }

        render() {
            return <Spin spinning={this.props.loading} tip={this.props.text}>
                <CompositionContainer {...this.props}/>
            </Spin>
        }
    }
);
